import format from 'string-format';
import { Api } from './Api';

class ProjectsApi extends Api {
  static URL_PROJECTS = 'integrations/projects';

  static URL_PROJECT_ID = 'integrations/projects/{referenceId}';

  static URL_PLATFORMS = 'integrations/platforms';

  static URL_PROJECT_INSTALLS = 'integrations/projects/{referenceId}/installations?limit=50';

  static URL_PROJECT_INSTALLS_CREATE = 'integrations/projects/{projectId}/installations';

  static URL_PROJECT_INSTALLS_PUT =
    'integrations/projects/{projectId}/installations/{installationId}';

  static URL_PROJECT_INSTALLS_DELETE = 'integrations/projects/{projectId}/installations/{tenantId}';

  static URL_PROJECT_LOGS =
    'integrations/projects/{referenceId}/audit?limit={limit}&offset={offset}';

  static URL_PROJECT_BLUEPRINTS = 'integrations/projects/{referenceId}/blueprints';

  static URL_PROJECT_EXPORT = 'integrations/projects/{referenceId}/export';

  static URL_PROJECT_IMPORT = 'integrations/projects/import';

  createProject(projectForm) {
    return this.post(ProjectsApi.URL_PROJECTS, projectForm);
  }

  updateProject(projectForm) {
    return this.put(`${ProjectsApi.URL_PROJECTS}/${projectForm.id}`, projectForm);
  }

  deleteProject(referenceId) {
    const deleteKeyUrl = format(ProjectsApi.URL_PROJECT_ID, { referenceId });
    return this.delete(deleteKeyUrl);
  }

  exportProject(referenceId) {
    return this.get(format(ProjectsApi.URL_PROJECT_EXPORT, { referenceId }));
  }

  importProject(projectJson) {
    return this.post(ProjectsApi.URL_PROJECT_IMPORT, projectJson);
  }

  getProject(referenceId) {
    return this.get(format(ProjectsApi.URL_PROJECT_ID, { referenceId }));
  }

  getProjects() {
    return this.get(ProjectsApi.URL_PROJECTS);
  }

  getBlueprintsByProjectId(referenceId) {
    return this.get(format(ProjectsApi.URL_PROJECT_BLUEPRINTS, { referenceId }));
  }

  getPlatforms() {
    return this.get(ProjectsApi.URL_PLATFORMS);
  }

  getProjectInstalls(referenceId) {
    return this.get(format(ProjectsApi.URL_PROJECT_INSTALLS, { referenceId }));
  }

  createProjectInstall(projectId, install) {
    const url = format(ProjectsApi.URL_PROJECT_INSTALLS_CREATE, {
      projectId,
    });
    return this.post(url, install);
  }

  updateProjectInstall(projectId, install) {
    const url = format(ProjectsApi.URL_PROJECT_INSTALLS_PUT, {
      projectId,
      installationId: install.referenceId,
    });
    return this.put(url, install);
  }

  deleteProjectInstall(projectId, install) {
    const url = format(ProjectsApi.URL_PROJECT_INSTALLS_DELETE, {
      projectId,
      tenantId: install.tenantId,
    });
    return this.delete(url, install);
  }

  getProjectLogs(referenceId, limit, offset) {
    return this.get(format(ProjectsApi.URL_PROJECT_LOGS, { referenceId, limit, offset }));
  }
}

export default ProjectsApi;
